import './bootstrap.js';

import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import FloatingVue from 'floating-vue';
import Coloris from '@melloware/coloris';
import { __ } from '@/base.js';
import { initializeSentry } from '@/vendor/sentry.js';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
	title: (title) => `${title} - ${appName}`,
	resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
	setup({ el, App, props, plugin }) {
		const pinia = createPinia();
		const app = createApp({ render: () => h(App, props) });

		if (import.meta.env.VITE_SENTRY_ENABLED === 'true') {
			initializeSentry(app);
		}

		app
			.use(plugin)
			.use(pinia)
			.use(FloatingVue, {
				distance: 10,
				themes: {
					tooltip: {
						triggers: window.matchMedia('(hover: hover)').matches ? ['hover', 'touch', 'focus'] : ['manual'],
						placement: 'top',
						autoHide: false,
						delay: {
							show: 0,
							hide: 0,
						},
					},
				},
			})
			.mixin({ methods: { route } })
			.mixin({ methods: { __ } })
			.mount(el);

		return app;
	},
	progress: {
		color: '#21D881',
	},
});

Coloris.init();
Coloris({
	el: '.is-color-picker',
	format: 'hex',
	alpha: false,
	margin: 8,
	swatches: [
		'#DF536B',
		'#61D04F',
		'#2297E6',
		'#28E2E5',
		'#CD0BBC',
		'#F5C710',
	],
});

import.meta.glob([
	'../assets/**',
]);
